import { createGroup, createRouter, replaceUnsafe } from "@swan-io/chicane";

export const routes = {
  CardWidgetLive: "/card/live/widget/:token",
  CardWidgetSandbox: "/card/sandbox/widget/:token",
  CardChoosePinLive: "/card/live/choose-pin/:token",
  CardChoosePinSandbox: "/card/sandbox/choose-pin/:token",
  ExpiredLink: "/404",
  Logout: "/logout?:logout_challenge",
  LogoutCallback: "/logout/callback",

  // Static page for end of flow where branding information isn't available
  Done: "/done",

  Login: "/login?&:step{waitingScreen}&:login_challenge",
  Consent: "/consent?:consentId",

  AuthenticatorRegisterBiometryIfApplicable: "/authenticator/biometry-check?:nextUrl",
  AuthenticatorRegisterBiometry: "/authenticator/biometry?:nextUrl",
  AuthenticatorClear: "/authenticator/clear",

  RegisterDevice: "/register-device?:nextUrl",

  ...createGroup("Authenticator", "/authenticator", {
    Area: "/*",

    ...createGroup("Login", "/login", {
      Root: "/?:login_challenge",
      OpenBanking: "/open-banking/:paymentSessionId?:nextUrl",
      ...createGroup("Identification", "/identification?:nextUrl", {
        Area: "/*",
        Root: "/",
        // we make `flow` part of the route because of the Fourthline flow,
        // which is currently in two successive steps
        Flow: "/:flow{UbbleExpert|UbblePVID|FourthlineQESStepExpert|FourthlineQESStepSignature|FourthlineQESWorkflow}",
      }),
      VerifyEmail: "/verify-email?:nextUrl&:email",
    }),

    ...createGroup("Consent", "/consent", {
      Root: "/?:consent_challenge&:consentId",
    }),

    ...createGroup("Recover", "/recover?:nextUrl", {
      Process: "/process",
      Passcode: "/passcode?:step",
      Success: "/success",
    }),

    Granted: "/granted",
    Refused: "/refused",
  }),
} as const;

export const Router = createRouter(routes);

export const safeRedirect = (
  url: string,
  { clearSession }: { clearSession: boolean } = { clearSession: false },
) => {
  const isInternalLink = url[0] === "/" || url[0] === "?" || url[0] === "#";

  if (clearSession) {
    window.sessionStorage.clear();
  }

  if (isInternalLink) {
    return replaceUnsafe(url);
  }

  const { origin } = window.location;

  if (url.startsWith(`${origin}/`)) {
    return replaceUnsafe(url.substring(origin.length));
  }

  return window.location.replace(url);
};

import { Future, Result } from "@swan-io/boxed";
import {
  Client,
  ClientError,
  InvalidGraphQLResponseError,
  MakeRequest,
  MutationConfig,
  TypedDocumentNode,
  parseGraphQLError,
  print,
  useDeferredQuery,
  useMutation,
  useQuery,
} from "@swan-io/graphql-client";
import { Request, badStatusToError, emptyToError } from "@swan-io/request";
import { GraphQLError } from "graphql";
import { P, match } from "ts-pattern";
import adminSchemaConfig from "../../../../scripts/graphql/dist/admin-schema-config.json";
import exposedInternalSchemaConfig from "../../../../scripts/graphql/dist/live-exposed-internal-schema-config.json";
import liveUnauthenticatedSchemaConfig from "../../../../scripts/graphql/dist/live-unauthenticated-schema-config.json";

import { AuthenticationResponseJSON } from "@simplewebauthn/types";
import { registerErrorToRequestId } from "@swan-io/lake/src/state/toasts";
import { customAlphabet } from "nanoid";
import { useCallback, useMemo } from "react";
import { stringToBase64url } from "./base64url";
import { env } from "./env";
import { JWTPayload, SignMachine, clearSignMachine, signJwt } from "./signMachine";
const alphabet = "0123456789abcdefghijklmnopqrstuvwxyz";
const nanoid = customAlphabet(alphabet, 8);

const generateTraceId = () => {
  const buffer = new Uint8Array(16);
  crypto.getRandomValues(buffer);
  return Array.from(buffer, value => value.toString(16).padStart(2, "0")).join("");
};

const generateSpanId = () => {
  const buffer = new Uint8Array(8);
  crypto.getRandomValues(buffer);
  return Array.from(buffer, value => value.toString(16).padStart(2, "0")).join("");
};

const traceparentVersion = "00";
const traceFlags = "01";

export const errorToRequestId = new WeakMap<WeakKey, string>();
registerErrorToRequestId(errorToRequestId);

const isUnauthorizedResponse = (response: unknown) => {
  const value = response as { status?: number; statusCode?: number } | undefined;
  return value?.status === 401 || value?.statusCode === 401;
};

const isUnauthorizedLikeString = (value: string) => {
  const lowerCased = value.toLowerCase();
  return lowerCased.includes("unauthenticated") || lowerCased.includes("unauthorized");
};

export const filterOutUnauthorizedError = (clientError: ClientError) => {
  if (
    ClientError.toArray(clientError).some(error => {
      return match(error)
        .with({ status: 401 }, () => true)
        .with({ extensions: { response: P.select() } }, response =>
          isUnauthorizedResponse(response),
        )
        .with({ message: P.select(P.string) }, message => isUnauthorizedLikeString(message))
        .otherwise(() => false);
    })
  ) {
    // never resolve, this way we never trigger an error screen
    return Future.make<Result<unknown, ClientError>>(() => {
      clearSignMachine().tap(() => {
        window.location.reload(); // Force page reload
      });
    });
  } else {
    return Future.value(Result.Error(clientError));
  }
};

const makeRequest: MakeRequest = ({ url, headers, operationName, document, variables }) => {
  const requestId = "req-" + nanoid();
  const traceparent = `${traceparentVersion}-${generateTraceId()}-${generateSpanId()}-${traceFlags}`;

  return Request.make({
    url,
    method: "POST",
    responseType: "json",
    headers: {
      ...headers,
      "x-swan-request-id": requestId,
      traceparent,
    },
    body: JSON.stringify({
      operationName,
      query: print(document),
      variables,
    }),
  })
    .mapOkToResult(badStatusToError)
    .mapOkToResult(emptyToError)
    .mapOkToResult(payload =>
      match(payload as unknown)
        .returnType<Result<unknown, GraphQLError[] | InvalidGraphQLResponseError>>()
        .with({ errors: P.select(P.array()) }, errors =>
          Result.Error(errors.map(parseGraphQLError)),
        )
        .with({ data: P.select(P.nonNullable) }, data => {
          return Result.Ok(data);
        })
        .otherwise(response => Result.Error(new InvalidGraphQLResponseError(response))),
    )
    .flatMapError(filterOutUnauthorizedError)
    .tapError(errors => {
      ClientError.forEach(errors, error => {
        try {
          navigator.sendBeacon(
            "/api/errors/report",
            JSON.stringify({
              clientRequestId: requestId,
              clientErrorName: error.name,
              clientErrorMessage: error.message,
            }),
          );
        } catch (err) {}
        errorToRequestId.set(error, requestId);
      });
    });
};

export const adminClient = new Client({
  url: env.ADMIN_AUTH_API_URL,
  makeRequest,
  schemaConfig: adminSchemaConfig,
});

export const liveUnauthenticatedClient = new Client({
  url: "/api/live-unauthenticated",
  makeRequest,
  schemaConfig: liveUnauthenticatedSchemaConfig,
});

export const sandboxUnauthenticatedClient = new Client({
  url: "/api/sandbox-unauthenticated",
  makeRequest,
  schemaConfig: liveUnauthenticatedSchemaConfig,
});

export const liveExposedInternalClient = new Client({
  url: env.PUBLIC_EXPOSED_INTERNAL_LIVE_FEDERATION_URL,
  makeRequest,
  schemaConfig: exposedInternalSchemaConfig,
});

export const sandboxExposedInternalClient = new Client({
  url: env.PUBLIC_EXPOSED_INTERNAL_SANDBOX_FEDERATION_URL,
  makeRequest,
  schemaConfig: exposedInternalSchemaConfig,
});

export const useSignedQuery = <Data, Variables>(
  query: TypedDocumentNode<Data, Variables>,
  variables: Variables,
  signMachine: SignMachine,
) => {
  return useQuery(query, variables, { overrides: { headers: signMachine.headers } });
};

export const useSignedDeferredQuery = <Data, Variables>(
  query: TypedDocumentNode<Data, Variables>,
  signMachine: SignMachine,
) => {
  const [data, config] = useDeferredQuery(query);

  const exposedConfig = useMemo(() => {
    const query = config.query;
    return {
      ...config,
      query: (variables: Variables) =>
        query(variables, { overrides: { headers: signMachine.headers } }),
    };
  }, [config, signMachine]);

  return [data, exposedConfig] as const;
};

export const useSignedMutation = <Data, Variables>(
  mutation: TypedDocumentNode<Data, Variables>,
  signMachine: SignMachine,
  options?: MutationConfig<Data, Variables> | undefined,
) => {
  const [mutate, data, rest] = useMutation(mutation, options);

  const wrappedMutate = useCallback(
    (variables: Variables) => {
      return mutate(variables, { overrides: { headers: signMachine.headers } });
    },
    [mutate, signMachine],
  );

  return [wrappedMutate, data, rest] as const;
};

export const useWebAuthnSignedMutation = <Data, Variables>(
  mutation: TypedDocumentNode<Data, Variables>,
  options?: MutationConfig<Data, Variables> | undefined,
) => {
  const [mutate, data] = useMutation(mutation, options);

  const wrappedMutate = useCallback(
    (variables: Variables, { credential }: { credential: AuthenticationResponseJSON }) => {
      return mutate(variables, {
        overrides: {
          headers: {
            "x-swan-webauthn-token": `Bearer ${stringToBase64url(JSON.stringify(credential))}`,
          },
        },
      });
    },
    [mutate],
  );

  return [wrappedMutate, data] as const;
};

export const useSignedMutationWithPayload = <Data, Variables>(
  mutation: TypedDocumentNode<Data, Variables>,
  signMachine: SignMachine,
  options?: MutationConfig<Data, Variables> | undefined,
) => {
  const [mutate, data] = useMutation(mutation, options);

  const wrappedMutate = useCallback(
    (variables: Variables, payload: JWTPayload) => {
      return signJwt(
        { authenticatorId: signMachine.authenticatorId, privateKey: signMachine.privateKey },
        payload,
      ).flatMapOk(token =>
        mutate(variables, {
          overrides: { headers: { "x-swan-authenticator-token": `Bearer ${token}` } },
        }),
      );
    },
    [mutate, signMachine],
  );

  return [wrappedMutate, data] as const;
};
